
<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="">
            <div class="card-header">
              <h1 class="mb-0 text-left">Genel Ayarlar</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
        <div class="container setting">
              <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center mt-3 h-60">
                    <h2 class="mr-2">Required Build Version</h2>
                    <input type="text" class="form-control" v-model.number="rowz.version.value"/>
                    <button type="button" class="btn mr-1 btn-warning open-mdl ml-3"  @click="_updateSettings()"><i class="fa fa-edit mr-1"></i> Ayarı Güncelle</button>
                </div>
                <h2 class="mt-3 font-bold" style="font-size:24px;color:#f6bb42 !important">Aktif Oyuncu Sayısı :  {{liveData.count}} </h2>
              </div>
                <div class="d-flex align-items-center mt-3 mb-5 h-70">
                                             <div class="d-flex align-items-center">
                                                <h2 class="mr-2">Bakım Başlangıç Tarihi</h2>
                                               <datetime type="datetime" v-model="startDate"></datetime>
                                             </div>
                                            <div class="d-flex align-items-center">
                                              <h2 class="mr-2">Bakım Bitiş Tarihi</h2>
                                              <datetime type="datetime" v-model="endDate"></datetime>
                                            <button type="button" class="btn mr-1 btn-warning open-mdl ml-3"  @click="_updateMaintenance()"><i class="fa fa-edit mr-1"></i> Bakım Tarihi Ekle</button>
                                            </div>
                            </div>
                   
                      
                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
          
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;justify-content:center;">
                            <button type="button" class="btn btn-warning open-mdl" v-bind:id="props.row._id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Görüntüle</button>
                            <!-- <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button> -->
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>                   
        </div>


			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
import io from "socket.io-client";
window.$ = JQuery;
import 'vue-datetime/dist/vue-datetime.css';

import Swal from 'sweetalert2'

export default {
  name: "Settings",
  components: {
    Modal,
  },
  data(){
    return {
       columns: [
        {
          label: 'ID',
          field: 'id',
        },
         {
          label: 'Başlangıç',
          field: 'start',
        },
        {
          label: 'Bitiş',
          field: 'end',
        },
      ],
      showModal:false,
      rowz: {
        version: {
          key: '',
          _id: '',
          value: 0
        }
      },
      startDate: {},
      endDate: {},
      liveData: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      imagePath: '',
      imageURL: '',
      imageLoadStatus: true,
      imgPath:'',
      maintenance:{},
      imageUpload: false,
    }
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/client-version', {params:{

            }})
              .then((response) => {
               this.rowz = response.data;
                console.log(this.response);
            });
          
          },
          
          _getListtData(){
            axios.get( this.$hostname +'/maintenance', {params:{

            }})
              .then((response) => {
                //console.log(this.rows);
                const data = response.data.map(item => {
                  const start = new Date(item.start);
                  start.setUTCHours(start.getHours());
                  const end = new Date(item.end);
                  end.setUTCHours(end.getHours());
                  return {start:start.toISOString().replace(/T/, ' ').replace(/\..+/, ''),end:end.toISOString().replace(/T/, ' ').replace(/\..+/, ''),id:item._id};
                })
                
             
                  console.log(data);
                  this.rows = data;
              
            

            });
          
          },
          _getGameDetail(row){
            this.actionType = "update";
            this.showModal = true;
            this.modalDetailData = row;
            console.log('listed data' ,this.modalDetailData);
            // this.number = event.currentTarget.id;
            // console.log(this.number); 
             this.imgPath = 'http://'+this.modalDetailData.specialImage;
            console.log('row data',row);

          },

          _updateSettings(){
  
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {

                  axios.post(this.$hostname +'/client-version', {
                          "version": this.rowz.version.value
                      } , {headers: { 'Content-Type': 'application/json'}})
                      .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log('Error', error.message);
                            }

                  });
                  //    axios.post('https://adminapi.renktekal.com/maintenance', {start: this.startDate, end: this.endDate} , {headers: { 'Content-Type': 'application/json'}})
                  //     .catch(function (error) {
                  //           if (error.response) {
                  //             // Request made and server responded
                  //             console.log(error.response.data);
                  //             console.log(error.response.status);
                  //             console.log(error.response.headers);
                  //           } else if (error.request) {
                  //             // The request was made but no response was received
                  //             console.log(error.request);
                  //           } else {
                  //             // Something happened in setting up the request that triggered an Error
                  //             console.log('Error', error.message);
                  //           }

                  // })
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
              _updateMaintenance(){
  
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                     axios.post(this.$hostname +'/maintenance', {start: this.startDate, end: this.endDate} , {headers: { 'Content-Type': 'application/json'}})
                      .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log('Error', error.message);
                            }

                  })
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          _newGameDataModal(){
           this.modalDetailData = this.modalNewData;
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.modalDetailData);
          },
          _createGame(){
            axios.post(this.$hostname +'/client-version', {
                  "version": this.rowz.version.value
              } , {headers: { 'Content-Type': 'application/json'}})
              .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  }).then(() => {
                 this.showModal = false;
                 this._getListData();
              })
          },
          _ioConnect(){
           console.log("kk");
              const socket = io('https://adminapi.renktekal.com/');
                socket.on("userCount", data => {
                   this.liveData = data;
                   
                       
                        //console.log(this.liveData);
            
                });
       },
  

  },
  mounted(){
    this._getListData();
    this._getListtData();
    this._ioConnect();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>

<template>
        <div class="app-content content home">
            <div class="login-page d-flex justify-content-center align-items-center">
                <div class="login-form">
                       <input type="text" class="form-control" v-model="userName" placeholder="id"/>
                        <input type="text" class="form-control" v-model="password" placeholder="şifre"/>
                        <button type="button" class="btn mr-1 btn-warning open-mdl" @click="login()"><i class="fa fa-edit mr-1"></i> Giriş Yap</button>
                </div>
            </div>
        </div>
</template>
      
      <script>
        import axios from 'axios';
        axios.defaults.withCredentials = true;
        import JQuery from 'jquery';
        window.$ = JQuery;
        import Swal from 'sweetalert2';
        var setCookie = require('set-cookie-parser');

      export default {
       name: "Login",
        components: {
   
        },
        data(){
            return {
                    userName: '',
                    password: '',
            }
        },
          methods: {
              login(){
                  console.log("kk");
                axios.post('https://adminapi.renktekal.com/login', {
                    username: this.userName,
                    password: this.password
                },  {withCredentials: true, headers: {'Content-Type': 'application/json','Access-Control-Allow-Origin': '*',crossDomain: true}})
                .then(res => {
                    console.log(res.headers);
                    var cookies = setCookie.parse(res, {
                    decodeValues: true  // default: true
                });

                cookies.forEach(console.log);
                }).catch(err => {
                    console.log(err);
                });
              }
          }
      }
      </script>
      
      <style>
      
      </style>
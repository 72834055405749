
<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="">
            <div class="card-header">
              <h1 class="mb-0 text-left">Bildirim Gönder</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
        <div class="container setting">
              <div class="d-flex justify-content-between align-items-center">
                
  
                  <div class="d-flex align-items-center mt-3 h-70 w-100 position-relative"> 
                   
                    <h2 class="mr-2">Başlık (TR)</h2>
                    <input type="text" class="form-control w-100 mw-100"  v-model="notify.tr.title"/>
                  
                </div>
              </div>
                  <div class="d-flex align-items-center mt-3 h-70 w-100 position-relative limit">
                    <h2 class="mr-2">Metin (TR)</h2>
                   
                      <p><span v-bind:class="{'text-danger': generateErr }">{{totalRemainCount}}</span></p>
                    <textarea v-model="notify.tr.text" v-on:keyup="liveCountDown"></textarea>
                  
                </div>
                 <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center mt-3 h-70 w-100">
                    <h2 class="mr-2">Başlık (EN)</h2>
                  
                    <input type="text" class="form-control w-100 mw-100" v-model="notify.en.title"/>
                  
                </div>
              </div>
                  <div class="d-flex align-items-center mt-3 h-70 w-100 position-relative limit">
                    <h2 class="mr-2">Metin (EN)</h2>
                      <p><span v-bind:class="{'text-danger': generatteErr }">{{totallRemainCount}}</span></p>
                    <textarea v-model="notify.en.text"  v-on:keyup="liveCountDownEn"></textarea>
                  
                </div>
                <div class="d-flex align-items-center justify-content-end mt-3 mb-5 h-70">
                             
                                            <button type="button" class="btn mr-1 btn-warning open-mdl ml-3"  @click="sendNotification()"><i class="fa fa-edit mr-1"></i> Bildirim Gönder</button>
                               
                            </div>
                   
                      
                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
          
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;justify-content:center;">
                            <button type="button" class="btn btn-warning open-mdl" v-bind:id="props.row._id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Görüntüle</button>
                            <!-- <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button> -->
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>                   
        </div>


			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
import io from "socket.io-client";
window.$ = JQuery;
import 'vue-datetime/dist/vue-datetime.css';

import Swal from 'sweetalert2'

export default {
  name: "Notifications",
  components: {
    Modal,
  },
  data(){
    return {
       columns: [
        {
          label: 'Başlık',
          field: 'title',
        },
         {
          label: 'Metin',
          field: 'text',
        },
        {
          label: 'Tip',
          field: 'topic',
        },
      ],
          rowz: {
        version: {
          key: '',
          _id: '',
          value: 0
        }
      },
      rows: [],
      showModal:false,
      notify: {tr: {text: '', title: ''}, en: {text: '', title: ''}},
      startDate: {},
      endDate: {},
      liveData: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      imagePath: '',
      imageURL: '',
      imageLoadStatus: true,
      imgPath:'',
      maintenance:{},
      imageUpload: false,
      limitmaxCount: 240,
        totallRemainCount: 240,
        limittmaxCount: 240,
        totalRemainCount: 240,
        theMessage: '',
        generatteErr: false,
        generateErr: false
    }
  },

  methods: {
           liveCountDown: function() {
              this.totalRemainCount = this.limitmaxCount - this.notify.tr.text.length;
              this.generateErr = this.totalRemainCount < 0;
            },
            liveCountDownEn: function() {
              this.totallRemainCount = this.limittmaxCount - this.notify.en.text.length;
              this.generatteErr = this.totallRemainCount < 0;
            },
          _getListtData(){
            axios.get( this.$hostname +'/notification', {params:{

            }})
              .then((response) => {
                //console.log(this.rows);
        
                
             
                  console.log(response.data);
                  this.rows = response.data;
              
            

            });
          
          },
          _getGameDetail(row){
            this.actionType = "update";
            this.showModal = true;
            this.modalDetailData = row;
            console.log('listed data' ,this.modalDetailData);
            // this.number = event.currentTarget.id;
            // console.log(this.number); 
             this.imgPath = 'http://'+this.modalDetailData.specialImage;
            console.log('row data',row);

          },

          _updateSettings(){
                if(this.totalRemainCount < 0){
                  alert("Karakter sayısını geçtiniz");
                }
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {

                  axios.post(this.$hostname +'/client-version', {
                          "version": this.rowz.version.value
                      } , {headers: { 'Content-Type': 'application/json'}})
                      .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log('Error', error.message);
                            }

                  });
                  //    axios.post('https://adminapi.renktekal.com/maintenance', {start: this.startDate, end: this.endDate} , {headers: { 'Content-Type': 'application/json'}})
                  //     .catch(function (error) {
                  //           if (error.response) {
                  //             // Request made and server responded
                  //             console.log(error.response.data);
                  //             console.log(error.response.status);
                  //             console.log(error.response.headers);
                  //           } else if (error.request) {
                  //             // The request was made but no response was received
                  //             console.log(error.request);
                  //           } else {
                  //             // Something happened in setting up the request that triggered an Error
                  //             console.log('Error', error.message);
                  //           }

                  // })
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
              sendNotification(){
                 if(this.totalRemainCount < 0 || this.totallRemainCount < 0){
                  alert("Karakter limitini geçtiniz mesaj gönderilemiyor.");
                }else{
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                     axios.post(this.$hostname +'/notification', this.notify , {headers: { 'Content-Type': 'application/json'}})
                      .catch(function (error) {
                            if (error.response) {
                              // Request made and server responded
                              console.log(error.response.data);
                              console.log(error.response.status);
                              console.log(error.response.headers);
                            } else if (error.request) {
                              // The request was made but no response was received
                              console.log(error.request);
                            } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log('Error', error.message);
                            }

                  })
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
              }
          },
          _newGameDataModal(){
           this.modalDetailData = this.modalNewData;
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.modalDetailData);
          },
          _createGame(){
            axios.post(this.$hostname +'/client-version', {
                  "version": this.rowz.version.value
              } , {headers: { 'Content-Type': 'application/json'}})
              .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  }).then(() => {
                 this.showModal = false;
                 this._getListData();
              })
          },
 
  

  },
  mounted(){
    this._getListtData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>


<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Yan Oyun Listesi</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <!-- <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Oyun Ekle</button></li> -->
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
           <!-- use the modal component, pass in the prop -->
   
                                  <modal v-if="showModal" @close="showModal = false">
                                      <template v-slot:modal-header>
                                        <h1 >{{gameDetailData.name}}</h1>
                                        <input type="hidden" v-model="actionType">
                                      </template>
                                       <template v-slot:modal-body>
                                        <div class="d-flex m-body" v-if="Object.keys(gameDetailData).length > 0">
                                          <div class="col-md-5">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Oyun Adı</h2>
                                              <input type="text" class="form-control" disabled v-model="gameDetailData.name"/>
                                            </div>
                                            <!-- <div class="d-flex align-items-center mt-3 justify-content-start h-60">
                                              <h2 class="mr-2">Durum</h2>
                                              <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                            </div> -->
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Zamanlama</h2>
                                               <select class="form-control" id="basicSelect" v-model="gameDetailData.interval">
                                                <option></option>
                                                <option value="anytime">anytime</option>
                                              
                                            
                                              </select>
                                            </div>
                                            <!-- <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Saat</h2>
                                               <input type="text" class="form-control" v-model="gameDetailData.time.hour">
                                                 <h2 class="ml-2 mr-2">Dakika</h2>
                                              <input type="text" class="form-control" id="" v-model="gameDetailData.time.min">
                                            </div> -->
                                            <!-- <div class="d-flex align-items-center mt-3 h-60" v-if="gameDetailData.interval == 'weekly'">
                                              <h2 class="mr-2">Haftanın Günü</h2>
                                              <input type="text" class="form-control" id="" v-model="gameDetailData.time.dayInWeek">
                                            </div> -->
                                            <!-- <div class="d-flex align-items-center mt-3 h-60" v-if="gameDetailData.interval == 'onetime'">
                                              <h2 class="mr-2">Hangi Ay</h2>
                                              <input type="text" class="form-control" id="" v-model="gameDetailData.time.month">
                                              <h2 class="mr-2">Ayın Günü</h2>
                                              <input type="text" class="form-control" id="" v-model="gameDetailData.time.day">
                                            </div> -->
                                            <!-- <div class="d-flex align-items-center mt-3 h-60" v-if="gameDetailData.interval == 'monthly'">
                                               <h2 class="mr-2">Ayın Günü</h2> 
                                              <input type="text" class="form-control" id="" v-model="gameDetailData.time.month">
                                            </div> -->
                                            <div class="d-flex align-items-center mt-3 h-60" >
                                              <h2 class="mr-2">Bekleme Süresi</h2>
                                              <input type="number" required class="form-control" v-model.number="gameDetailData.properties.lobbyDuration"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Tur Arası</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.intermissionDuration"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Etkileşim Süresi</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.adDuration"/>
                                            </div>
                                             <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Maksimum Kişi Sayısı</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.maxPlayerCount"/>
                                            </div>
                                          </div>
                                          <div class="col-md-7">
                                            <div class="d-flex align-items-center h-60">
                                              <h2 class="mr-2">Pass Süresi</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.passAllowedDuration"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Reklam Olasılığı</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.adOfferPercentage"/>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">No Joker Player Count</h2>
                                              <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.noJokersAllowedPlayerCount"/>
                                            </div>
                                             <!-- <div class="d-flex align-items-center mt-3 h-60">
                                                <h2 class="mr-2">Pass Çarpanı</h2>
                                                <input type="text" class="form-control" v-model.number="gameDetailData.properties.roundPassMultiplier"/>
                                              </div> -->
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Akçe Kazanımı</h2>
                                              <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.roundReward"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.roundRewardIncrement"/>
                                              </div>
                                              <!-- <div class="d-flex flex-column ml-1">
                                                <span>Faz 1</span>
                                                <input type="text" class="form-control" v-model="gameDetailData.roundRewardPeriodMultiplier[1]"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Faz 2</span>
                                                <input type="text" class="form-control" v-model="gameDetailData.roundRewardPeriodMultiplier[2]"/>
                                              </div> -->
                                              <div class="d-flex flex-column ml-1 " v-for="(items,index) in (gameDetailData.properties.roundRewardPeriodMultiplier)" :key="index">
                                                <span>Faz {{index}} </span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.roundRewardPeriodMultiplier[index]"/>
                                              
                                              </div>
                                            </div>
                                            
                                            <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Değişim Ücreti</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.change.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.change.increment"/>
                                              </div>
                                            </div>
                                             <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Grafik Jokeri</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.graph.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Tur Artışı</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.graph.increment"/>
                                              </div>
                                            </div>
                                              <div class="d-flex align-items-center mt-3 h-60">
                                              <h2 class="mr-2">Geçiş Anahtarı</h2>
                                               <div class="d-flex flex-column">
                                                <span>Baz</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.pass.base"/>
                                              </div>
                                              <div class="d-flex flex-column ml-1">
                                                <span>Pass Çarpanı</span>
                                                <input type="number" required  class="form-control" v-model.number="gameDetailData.properties.prices.pass.increment"/>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="w-100 text-left mt-3">
                                          <h1>Ödüller</h1>
                                        </div>
                                          <div class="col-md-12 position-relative mt-5">
                                               <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                    Yeni Ekle
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control" v-model.number="newRank"/>
                                                          </div>
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" />ÖZEL<label for="switcher" class="sw-label">Toggle</label>para
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin</h2>
                                                              <input type="text" class="form-control" v-model.number="newCoin"/>
                                                            </div>
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Özel Ödül İmajı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Akçe</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                            <input type="text" class="form-control" v-model.number="newPass"/>
                                                          </div>
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button type="button" class="btn mr-1 mb-1 btn-success" @click="_addNewPrize()"><i class="fa fa-edit mr-1"></i> Ödülü Ekle</button>
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>




                                            <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Winner
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.winner.coin"/>
                                                            </div>
                                                             <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                              <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.winner.pass"/>
                                                            </div>
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Özel Ödül İmajı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Akçe</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Geçiş Anahtarı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                           
                                                           
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                               <vsa-list class="w-100">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Runnerups
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                            <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.runnerups.pass"/>
                                                          </div>
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="gameDetailData.properties.prizes.runnerups.coin"/>
                                                            </div>
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Özel Ödül İmajı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Akçe</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Geçiş Anahtarı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                      
                                                          
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                              <vsa-list class="w-100" v-for="item in gameDetailData.properties.prizes.qualifying">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                   Son{{item.rank}}
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                          <!-- <div class="d-flex align-items-center">
                                                            <h2 class="mr-2 col-md-6 text-left">Oyun Adı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Kalan Oyuncu</h2>
                                                            <input type="text" class="form-control" v-model.number="item.rank"/>
                                                          </div>
                                                            <!-- <div class="d-flex align-items-center mt-2">
                                                                <h2 class="mr-2 col-md-6 text-left">Durum</h2>
                                                                <input type="checkbox" id="switcher" class="m-switch" /><label for="switcher" class="sw-label">Toggle</label>
                                                              </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Coin Ödülü</h2>
                                                              <input type="text" class="form-control" v-model.number="item.prize.coin"/>
                                                            </div>
                                                                <div class="d-flex align-items-center mt-2">
                                                              <h2 class="mr-2 col-md-6 text-left">Pass</h2>
                                                              <input type="text" class="form-control" v-model.number="item.prize.pass"/>
                                                            </div>
                                                           <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Özel Ödül İmajı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Akçe</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Geçiş Anahtarı</h2>
                                                            <input type="text" class="form-control"/>
                                                          </div> -->
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                       
                                                            <button type="button" class="btn mr-1 mb-1 btn-danger" v-on:click="_removeElement(item)"><i class="fa fa-trash mr-1"></i> Ödülü Sil</button>
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                          </div>
                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button  v-if="actionType == 'update'" type="button" class="btn mr-1 mb-1 btn-success" v-bind:id="gameDetailData._id"  @click="_updateGame($event)"><i class="fa fa-edit mr-1"></i> Oyunu Güncelle</button>
                                                            <button  v-if="actionType == 'add'" type="button" class="btn mr-1 mb-1 btn-success"  @click="_createGame($event)"><i class="fa fa-edit mr-1"></i> Oyun Ekle</button>
                                                            <!-- <button v-if="actionType == 'update'"  type="button" class="btn mb-1  btn-danger delete" v-bind:id="gameDetailData._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button> -->
                                                          </div>
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;max-width:300px; justify-content:center">
                            <button type="button" class="btn mr-1 btn-warning open-mdl" v-bind:id="props.row._id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Düzenle</button>
                            <!-- <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button> -->
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2';

export default {
  name: "Subgames",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Oyun Adı',
          field: 'name',
        },
         {
          label: 'Oyun ID',
          field: '_id',
        },
        {
          label: 'Oyun Tipi',
          field: 'type',
          type: 'text',
        },
        {
          label: 'İşlem',
          field: 'before',
          width: '200px',
        }
      ],
       gameDetailData: 
                          {
                name: '',
                type: 'subgame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                },               
                properties:{
                  adDuration: 0,
                adOfferPercentage: 0.5,
                intermissionDuration: 30,
                maxPlayerCount: 0,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    coin: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                },
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: { 1:1,  2:2},
                specialImage: '',
                specialPrize: '',
                }
},
            newGameData: 
                          {
                name: '',
                type: 'subgame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                },               
                properties:{
                  adDuration: 0,
                adOfferPercentage: 0.5,
                intermissionDuration: 30,
                maxPlayerCount: 0,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    coin: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                },
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: { 1:1,  2:2},
                specialImage: '',
                specialPrize: '',
                }
},
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
    };
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/games', {params:{
            type: 'subgame',
            }})
              .then((response) => {
                this.rows = response.data;
                console.log();
            });
          },
          _getGameDetail(event){
            this.actionType = "update";
            this.showModal = true;
            this.gameDetailData = [];
            this.targetId = event.currentTarget.id;
          console.log(this.targetId); 

             axios.get( this.$hostname +'/games', {params:{
            id: this.targetId,
            }})
              .then((response) => {
                this.gameDetailData = response.data;
                console.log(this.gameDetailData);
            });
          },
           _deleteGame(event){
            this.removeId = event.currentTarget.id;
                Swal.fire({
                title: 'Silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                    console.log("remove id", this.removeId);
                  axios.delete(this.$hostname +'/games', {params:{
                  id: this.removeId,
                }})
                .then(
                     () =>
                  this._getListData(),
                  this.showModal = false
                )
                  Swal.fire(
                    'Silindi!',
                    'Oyun başarıyla silindi !',
                    'success'
                  )
                }
              })
              
           
          },
          _addNewPrize(){
              this.gameDetailData.properties.prizes.qualifying.push({rank: this.newRank, prize: {coin: this.newCoin, pass: this.newPass}});
            //  this.gameDetailData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
          },
          _removeElement(index) {
              this.gameDetailData.properties.prizes.qualifying.splice(index, 1);
            },
          _updateGame(event){
              this.updateId = event.currentTarget.id;
              console.log("update id", this.updateId);
              console.log(this.gameDetailData);
                    Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                  axios.put(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
                 .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  }).then(
                     () =>
                  this._getListData(),
                  this.showModal = false
                )
                  Swal.fire(
                    'Güncellendi!',
                    'Oyun başarıyla Güncellendi !',
                    'success'
                  )
                }
              })
           
          },
          _newGameDataModal(){
            this.gameDetailData = this.newGameData;
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.gameDetailData);
          },
          _createGame(){
        
               Swal.fire({
                title: 'Eklemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ekle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                  console.log("update id", this.updateId);
                  console.log(this.modalDetailData);
                  axios.post(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
                 .catch(function (error) {
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }

                  }).then(
                  () =>
                    this._getListData(),
                  this.showModal = false
                )
                  Swal.fire(
                    'Kayıt Eklendi!',
                    'İşlem başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          }
        

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>


<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Satın Alma Geçmişi</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Ayar Ekle</button></li>
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>

           <!-- use the modal component, pass in the prop -->
              <div class="col-md-12 mt-5">
                  <div class="d-flex">
               <input type="text" class="form-control srchh" v-model="searchByToken" placeholder="Token ile Ara"/>
              <button type="button" class="btn ml-1 mb-1 btn-success"  @click="_findToken()"><i class="fa fa-search mr-1"></i> Ara</button>
              </div>
              </div>
                                  <modal v-if="showModal" @close="showModal = false">
                                      <template v-slot:modal-header>
                                        <h1 >{{modalDetailData.key}}</h1>
                                       
                                        <input type="hidden" v-model="actionType">
                                      </template>
                                       <template v-slot:modal-body>
                                        <div class="d-flex m-body" >
                                          <div class="col-md-6">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Coin</h2>
                                              <input type="text" class="form-control" v-model.number="modalDetailData.coin"/>
                                            </div>
                                         
                                          </div>
                                           <div class="col-md-6">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Key</h2>
                                              <input type="text" class="form-control" v-model="modalDetailData.key"/>
                                            </div>
                                               </div>
                                          </div>
                                         
                                                   
                                                                      
                                                               
                                           
                                         
                                    
                                        <div class="w-100 text-left mt-3">
                                          <!-- <h1>Jokerler</h1> -->
                                        </div>
                                          <div class="col-md-12 position-relative mt-5">
                                              <vsa-list class="w-100" v-for="item in modalDetailData.jokers">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                  Pass Joker
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                        
                                                          <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">İd</h2>
                                                            <input type="text" class="form-control" v-model="item.id"/>
                                                          </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Miktar</h2>
                                                            <input type="text" class="form-control" v-model.number="item.quantity"/>
                                                          </div>
                                                   
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                       
                                    
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                          </div>
                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button  v-if="actionType == 'update'" type="button" class="btn mr-1 mb-1 btn-success" v-bind:id="listedData._id"  @click="_updateGame($event)"><i class="fa fa-edit mr-1"></i> Ayarı Güncelle</button>
                                                            <button  v-if="actionType == 'add'" type="button" class="btn mr-1 mb-1 btn-success"  @click="_createGame($event)"><i class="fa fa-edit mr-1"></i> Ayar Ekle</button>
                                                            <button type="button" class="btn mr-1 mb-1 btn-danger"><i class="fa fa-trash mr-1"></i> Ayarı Sil</button>
                                                          </div>
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table class="purchases"
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `Sayfa ${params.currentPage}`, 
                      }">
                       <template slot="table-row" slot-scope="props">

                          <span v-if="props.column.field == 'transactionId'" class="clip d-flex justify-content-between align-items-center">
                           <p class="clipped"  v-bind:data-id="props.row.transactionId">{{props.row.transactionId}} </p>
                            <button class="btn mr-1 mb-1 btn-warning" v-clipboard="() => props.row.transactionId">
                                <i class="fa fa-copy"></i>
                              </button>
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                  </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2'

export default {
  name: "Bundles",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Key',
          field: 'bundleKey',
        },
         {
          label: 'Transaction ID',
          field: 'transactionId',
        },
     
        {
          label: 'Store',
          field: 'store',

        },
          {
          label: 'User',
          field: 'userId',

        }
      ],
      listedData:[],
      number: 0,
      modalDetailData:{
          "coin": 0,
          "key": '',
          "specialImage": '',
          "jokers": [
            {
              "id": "pass",
              "quantity": 0
            }
          ],

        },
        modalNewData:{
          "coin": 0,
          "key": '',
          "jokers": [
            {
              "id": "pass",
              "quantity": 0
            }
          ],

        },
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      imagePath: '',
      imageURL: '',
      imageLoadStatus: true,
      imgPath:'',
      imageUpload: false,
      searchByToken: '',
      tokenResult: {},
    }
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/purchases', {params:{
            offset:0,
            limit:100
            }})
              .then((response) => {
                this.rows = response.data;
                console.log(this.rows);
            });
            let date = new Date("2021-05-02T19:37:57.296Z");
            console.log(date);
          },
          _getGameDetail(row){
            this.actionType = "update";
            this.showModal = true;
            this.modalDetailData = row;
            console.log('listed data' ,this.modalDetailData);
            // this.number = event.currentTarget.id;
            // console.log(this.number); 
             this.imgPath = 'http://'+this.modalDetailData.specialImage;
            console.log('row data',row);

          },
          _deleteGame(event){
              this.removeId = event.currentTarget.id;
              console.log("remove id", this.removeId);
              Swal.fire({
                title: 'Silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                
                    console.log("remove id", this.removeId);
                      axios.delete(this.$hostname +'/bundles', {params:{
                    id: this.removeId,
                  }}).then( () =>
                    this._getListData()
                  );
                  Swal.fire(
                    'Silindi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          copyClipboard(elem){


            console.log(elem);
              var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($(elem).text()).select();
            document.execCommand("copy");
          
          },
          _findToken(){
                axios.get( this.$hostname +'/purchases', {params:{
           transactionId: this.searchByToken,
            }})
               .then((response) => {
                 const tokenArray = [response.data];
                
                this.rows = tokenArray;
                console.log(this.rows);
            });
          },
          formatDate(e){
            const newDate = new Date(e);
            console.log(newDate);
          },
          _addNewPrize(){
              this.listedData.prizes.qualifying.push({rank: this.newRank, prize: {coin: this.newCoin, pass: this.newPass}});
            //  this.listedData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
          },
          _removeElement(index) {
              this.listedData.prizes.qualifying.splice(index, 1);
            },
          _updateGame(event){
  
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                  console.log("update id", this.updateId);
                  console.log(this.modalDetailData);
                  const {vgt_id,originalIndex,...args} = this.modalDetailData;
                  console.log(args);
                  axios.put(this.$hostname +'/bundles', args, {headers: { 'Content-Type': 'application/json'}})
                .then(
                  this.showModal = false
                
                )
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          _newGameDataModal(){
           this.modalDetailData = this.modalNewData;
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.modalDetailData);
          },
          _createGame(){
            axios.post(this.$hostname +'/bundles', this.modalDetailData, {headers: { 'Content-Type': 'application/json'}})
              .then(() => {
                 this.showModal = false;
                 this._getListData();
              })
          },
  

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>

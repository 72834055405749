<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Kullanıcılar</h1>

              <a class="heading-elements-toggle"
                ><i class="fa fa-ellipsis-v font-medium-3"></i
              ></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li>
                    <button
                      type="button"
                      class="btn mr-1 btn-warning open-mdl"
                      @click="_newGameDataModal()"
                    >
                      <i class="fa fa-edit mr-1"></i> Yeni Kullanıcı Ekle
                    </button>
                  </li>
                  <li>
                    <a data-action="collapse"><i class="ft-minus"></i></a>
                  </li>
                  <li>
                    <a @click="_getListData()"><i class="ft-rotate-cw"></i></a>
                  </li>
                  <li>
                    <a data-action="expand"><i class="ft-maximize"></i></a>
                  </li>
                  <li>
                    <a data-action="close"><i class="ft-x"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="text-left col-md-12 mt-5">
              <p
                class="font-bold mb-0"
                style="font-size:24px;color:#f6bb42 !important"
              >
                Toplam Kullanıcı : {{ countUser.count }}
              </p>
            </div>
            <div class="d-flex px-0 mt-3">
              <div class="col-md-6 ">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control srchh"
                    v-model="searchByUserName"
                    placeholder="İsim ile ara"
                  />
                  <button
                    type="button"
                    class="btn ml-1 mb-1 btn-success"
                    @click="_findUser()"
                  >
                    <i class="fa fa-search mr-1"></i> Ara
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control srchh"
                    v-model="searchByPhone"
                    placeholder="Telefon ile ara  "
                  />
                  <button
                    type="button"
                    class="btn ml-1 mb-1 btn-success"
                    @click="_findPhone()"
                  >
                    <i class="fa fa-search mr-1"></i>Ara
                  </button>
                </div>
                <p class="text-left">
                  Başında sıfır olmadan birleşik şekilde arayınız örn.
                  5432194475
                </p>
              </div>
            </div>
            <div class="d-flex mt-1">
              <div class="col-md-6 ">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control srchh"
                    v-model="searchByEmail"
                    placeholder="E-mail ile ara"
                  />
                  <button
                    type="button"
                    class="btn ml-1 mb-1 btn-success"
                    @click="_findEmail()"
                  >
                    <i class="fa fa-search mr-1"></i>Ara
                  </button>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control srchh"
                    v-model="searchById"
                    placeholder="User ID ile ara"
                  />
                  <button
                    type="button"
                    class="btn ml-1 mb-1 btn-success"
                    @click="_findUserId()"
                  >
                    <i class="fa fa-search mr-1"></i>Ara
                  </button>
                </div>
              </div>
            </div>

            <!-- use the modal component, pass in the prop -->

            <modal v-if="showModal" @close="showModal = false">
              <template v-slot:modal-header>
                <h1>
                  {{ modalDetailData.name }} {{ modalDetailData.lastname }}
                </h1>

                <input type="hidden" v-model="actionType" />
              </template>
              <template v-slot:modal-body>
                <div class="d-flex m-body">
                  <div class="col-md-6">
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Adı</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.name"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Telefon</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.phone"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Avatar</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.avatar"
                      />
                    </div>
                    <div
                      class="d-flex align-items-center h-60"
                      v-if="actionType == 'update'"
                    >
                      <h2 class="mr-2">Device ID</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.device_id"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2 w-125">Konuk Oyuncu</h2>

                      <div class="d-flex">
                        <label class="radio">
                          <input
                            type="radio"
                            name="guest"
                            id="one"
                            v-bind:value="true"
                            v-model="modalDetailData.guest"
                          />
                          <span>Evet</span>
                        </label>
                        <label class="radio">
                          <input
                            type="radio"
                            name="guest"
                            id="two"
                            v-bind:value="false"
                            v-model="modalDetailData.guest"
                          />
                          <span>Hayır</span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2 w-125">Mesaj İzni</h2>
                      <div class="d-flex">
                        <label class="radio">
                          <input
                            type="radio"
                            name="messages"
                            id="one"
                            v-bind:value="true"
                            v-model="modalDetailData.receivesMessages"
                          />
                          <span>Var</span>
                        </label>
                        <label class="radio">
                          <input
                            type="radio"
                            name="messages"
                            id="two"
                            v-bind:value="false"
                            v-model="modalDetailData.receivesMessages"
                          />
                          <span>Yok</span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Ana Oyun Kazanma</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model.number="modalDetailData.wonMain"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Yan Oyun Kazanma</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model.number="modalDetailData.wonSub"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Soyadı</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.lastname"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Email</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.email"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Skor</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model.number="modalDetailData.score"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Total</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model.number="modalDetailData.total"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Balance</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model.number="modalDetailData.balance"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">GPS ADID</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.gps_adid"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">IDFA</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.idfa"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">Skor</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.score"
                      />
                    </div>
                    <div class="d-flex align-items-center h-60">
                      <h2 class="mr-2">IBAN</h2>
                      <input
                        type="text"
                        class="form-control"
                        v-model="modalDetailData.iban"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-100 text-left mt-3">
                  <!-- <h1>Jokerler</h1> -->
                </div>
                <!-- <div class="col-md-12 position-relative mt-5">
                                              <vsa-list class="w-100" v-for="item in modalDetailData.jokers">
                                   <!-- Here you can use v-for to loop through items  --
                                                <vsa-item>
                                                  <vsa-heading>
                                                  Joker
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                        
                                                          <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">İd</h2>
                                                            <input type="text" class="form-control" v-model="item.id"/>
                                                          </div>
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Miktar</h2>
                                                            <input type="text" class="form-control" v-model="item.quantity"/>
                                                          </div>
                                                   
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                       
                                    
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                          </div> -->
                <div
                  class="w-100 d-flex align-items-center justify-content-end mt-3"
                >
                  <button
                    v-if="actionType == 'update'"
                    type="button"
                    class="btn mr-1 mb-1 btn-success"
                    v-bind:id="listedData._id"
                    @click="_updateGame($event)"
                  >
                    <i class="fa fa-edit mr-1"></i> Kullanıcıyı Güncelle
                  </button>
                  <button
                    v-if="actionType == 'add'"
                    type="button"
                    class="btn mr-1 mb-1 btn-success"
                    @click="_createGame($event)"
                  >
                    <i class="fa fa-edit mr-1"></i> Kullanıcı Ekle
                  </button>
                  <button type="button" class="btn mr-1 mb-1 btn-danger">
                    <i class="fa fa-trash mr-1"></i> Kullanıcıyı Sil
                  </button>
                </div>
              </template>
            </modal>
            <!-- MODAL END -->
            <div class="card-content collapse show">
              <div class="card-body card-dashboard">
                {{ this.rows._id }}
      <button
                    type="button"
                    class="btn mr-1 mb-1 btn-success"
                    @click="wonSort()"
                  >
                    <i class="fa fa-edit mr-1"></i> Kullanıcıyı Güncelle
                  </button>
                <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  :totalRows="this.countUser.count"
                  :search-options="{
                    enabled: true,
                  }"
                  :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'phone', type: 'desc'}
                  }"
                  :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: this.pageLimit,
                    position: 'top',
                    perPageDropdown: [10, 25, 50],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All',
                    infoFn: (params) => `Sayfa ${this.pageNumber}`,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field == 'before'"
                      style="display:flex;max-width:300px"
                    >
                      <button
                        type="button"
                        class="btn mr-1 btn-warning open-mdl"
                        v-bind:id="props.row.originalIndex"
                        @click="_getGameDetail(props.row)"
                      >
                        <i class="fa fa-edit mr-1"></i> Düzenle
                      </button>
                      <button
                        type="button"
                        class="btn  btn-danger delete"
                        v-bind:id="props.row._id"
                        @click="_deleteGame($event)"
                      >
                        <i class="fa fa-trash mr-1"></i>Sil
                      </button>
                    </span>
                    <span v-else-if="props.column.field == 'after'">
                      after
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import axios from "axios";
import JQuery from "jquery";
window.$ = JQuery;
import Swal from "sweetalert2";

export default {
  name: "Bundles",
  components: {
    Modal,
  },
  data() {
    return {
      columns: [
        {
          label: "Telefon",
          field: "phone",
        },
        {
          label: "ID",
          field: "_id",
        },
        {
          label: "Ana Oyun Kazanımı",
          field: "wonMain",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          label: "Yan Oyun Kazanımı",
          field: "wonSub",
        },
        {
          label: "Toplam Oyun",
          field: "total",
          sortable: true,
          sortFn: this.sortFn,
        },

        {
          label: "İşlem",
          field: "before",
          width: "200px",
        },
      ],
      listedData: [],
      rows: [],
      number: 0,
      modalDetailData: {
        balance: 0,
        score: 0,
        wonMain: 0,
        wonSub: 0,
        total: 0,
        receivesMessages: true,
        avatar: "",
        device_id: "",
        guest: true,
        gps_adid: null,
        idfa: null,
        email: "",
        lastname: "",
        name: "",
        phone: "",
        iban: "",
      },
      modalNewData: {
        balance: 0,
        score: 0,
        wonMain: 0,
        wonSub: 0,
        total: 0,
        receivesMessages: true,
        avatar: "",
        device_id: undefined,
        guest: true,
        gps_adid: null,
        idfa: null,
        email: "",
        lastname: "",
        name: "",
        phone: "",
        iban: "",
      },
      showModal: false,
      rows: [],
      targetId: "",
      newData: [],
      removeId: "",
      updateId: "",
      newRank: "0",
      newCoin: "0",
      newPass: "0",
      actionType: "",
      imagePath: "",
      imageURL: "",
      imageLoadStatus: "s",
      searchByUserName: "",
      searchByPhone: "",
      searchByEmail: "",
      pageLimit: 10,
      pageOffset: 1,
      pageNumber: 1,
      countUser: {},
      searchById: "",
    };
  },

  methods: {
    sortFn(x, y, col, rowX, rowY) {
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2
      return (x < y ? -1 : (x > y ? 1 : 0));
    },
    _getListData() {
      axios
        .get(this.$hostname + "/users", {
          params: {
            limit: this.pageLimit,
            offset: 0,
          },
        })
        .then((response) => {
          this.rows = response.data;
          console.log(this.rows);
        });
    },
    _getCount() {
      axios
        .get(this.$hostname + "/users/count", { params: {} })
        .then((response) => {
          this.countUser = response.data;
          this._getListData();
          console.log(response.data);
        });

    },
    wonSort() {
      axios
        .get(this.$hostname + "/users", { params: {
          sort: "wonMain",
          orderBy: 1,
          offset: 0,
          limit: 1000
        } })
        .then((response) => {
          this.rows = response.data;
          console.log(response.data);
        });

    },
    _getGameDetail(row) {
      this.actionType = "update";
      this.showModal = true;
      this.modalDetailData = row;
      console.log("listed data", this.modalDetailData);
      // this.number = event.currentTarget.id;
      // console.log(this.number);
      console.log(this.makeid(5));
      console.log("row data", row);
    },
    _deleteGame(event) {
      this.removeId = event.currentTarget.id;
      Swal.fire({
        title: "Silmek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil!",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("remove id", this.removeId);
          axios
            .delete(this.$hostname + "/users", {
              params: {
                id: this.removeId,
              },
            })
            .then(() => this._getListData());
          Swal.fire(
            "Silindi!",
            "Güncelleme başarıyla gerçekleştirildi !",
            "success"
          );
        }
      });
    },
    onPageChange(params) {
      console.log("k");
      this.pageNumber = this.pageNumber + 1;
      params.currentPage = this.pageNumber;
      console.log("page number", this.pageNumber);
      this.pageOffset = this.pageLimit * (this.pageNumber - 1);
      console.log(this.pageOffset);
      axios
        .get(this.$hostname + "/users", {
          params: {
            limit: this.pageLimit,
            offset: this.pageOffset,
          },
        })
        .then((response) => {
          this.rows = response.data;
          console.log(this.rows);
        });
    },
    onPerPageChange(params) {
      this.pageLimit = params.currentPerPage;
      console.log("limit", this.pageLimit);
    },
    makeid(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    _findUser() {
      axios
        .get(this.$hostname + "/users", {
          params: {
            name: this.searchByUserName,
          },
        })
        .then((response) => {
          console.log(this.searchByUserName);

          this.rows = response.data;
          console.log(this.rows);
        });
    },
    _findEmail() {
      axios
        .get(this.$hostname + "/users", {
          params: {
            email: this.searchByEmail,
          },
        })
        .then((response) => {
          console.log(this.searchByEmail);
          this.rows = response.data;
          console.log(response);
        });
    },
    _findUserId() {
      let idd = this.searchById;
      console.log(idd);
      axios
        .get(this.$hostname + "/users", {
          params: {
            id: this.searchById,
          },
        })
        .then((response) => {
          const userArray = [response.data];
          console.log(response.data);
          this.rows = userArray;
          console.log(response.data);
        });
    },
    _findPhone() {
      let phonee = "+90" + this.searchByPhone;
      console.log(phonee);
      axios
        .get(this.$hostname + "/users", {
          params: {
            phone: "+90" + this.searchByPhone,
          },
        })
        .then((response) => {
          console.log(this.searchByPhone);
          console.log(response.data);
          this.rows = response.data;
          console.log(this.rows);
        });
    },
    _addNewPrize() {
      this.listedData.prizes.qualifying.push({
        rank: this.newRank,
        prize: { coin: this.newCoin, pass: this.newPass },
      });
      //  this.listedData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
    },
    _removeElement(index) {
      this.listedData.prizes.qualifying.splice(index, 1);
    },
    _updateGame(event) {
      Swal.fire({
        title: "Güncellemek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Güncelle!",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          //this.updateId = event.currentTarget.id;
          console.log("update id", this.updateId);
          console.log(this.modalDetailData);
          const { vgt_id, originalIndex, ...args } = this.modalDetailData;
          console.log(args);
          axios
            .put(this.$hostname + "/users", args, {
              headers: { "Content-Type": "application/json" },
            })
            .catch(function(error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .then((this.showModal = false));
          Swal.fire(
            "Güncellendi!",
            "Güncelleme başarıyla gerçekleştirildi !",
            "success"
          );
        }
      });
    },
    _newGameDataModal() {
      this.modalDetailData = this.modalNewData;
      let newId = this.makeid(5);
      console.log(newId);
      this.modalDetailData.device_id = this.makeid(5);
      this.showModal = true;
      this.actionType = "add";
      console.log("show modal");
      console.log("show modal", this.modalDetailData);
    },
    _createGame() {
      Swal.fire({
        title: "Eklemek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ekle!",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          //this.updateId = event.currentTarget.id;
          console.log("update id", this.updateId);

          console.log(this.modalDetailData);
          axios
            .post(this.$hostname + "/users", this.modalDetailData, {
              headers: { "Content-Type": "application/json" },
            })
            .catch(function(error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .then(() => this._getListData(), (this.showModal = false));
          Swal.fire(
            "Kayıt Eklendi!",
            "İşlem başarıyla gerçekleştirildi !",
            "success"
          );
        }
      });
    },
  },
  mounted() {
    this._getCount();
    
  },
  computed: {
    sortedData() {},
  },
};
</script>

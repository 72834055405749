
<template>
  <div class="app-content content home">
    <section id="base-style statisctics-container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Geçmiş Oyunlar</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <!-- <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Oyun Ekle</button></li> -->
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
           <!-- use the modal component, pass in the prop -->
   
                                  <modal v-if="showModal" @close="showModal = false, statisticOpen = true">
                                    <template v-slot:modal-header>
                                     
                                    </template>
                                       <template v-slot:modal-body>
                                 
                                         <div class="statistic" v-if="statisticOpen == true">
                                              <h2>LeaderBoard</h2>
                                              <table class=" table table-hover table-borderless table-dark">
                                                <tr>
                                                  <th>UserID</th>
                                                  <th>Coin</th>
                                                  <th>Harcanan</th>
                                                  <th scope="col">T.G.J.</th>
                                                  <th scope="col">T.D.J.</th>
                                                  <th scope="col">T.G.A.</th>
                                                  <th>Konuk Oyuncu</th>
                                                  <th>Kazanan</th>
                                                </tr>
                                                <tr   class="sortedid" v-for="lead in gameDetailData.leaderboard">
                                                  <td><span v-bind:id="lead.userId" @click="getUserDetail($event)">{{lead.userId}}</span></td>
                                                  <td>{{lead.coins}}</td>
                                                  <td>{{lead.coinsSpent}}</td>
                                                  <td>{{lead.graphsUsed}}</td>
                                                  <td>{{lead.changesUsed}}</td>
                                                  <td>{{lead.passesUsed}}</td>
                                                  <td v-if="lead.isGuest == true"><i class="fa fa-check"></i></td>
                                                  <td v-else><i class="fa fa-times"></i></td>
                                                  <td v-if="lead.isWinner == true"><i class="fa fa-check"></i></td>
                                                  <td v-else><i class="fa fa-times"></i></td>
                                                </tr>
                                              </table>
                                                <h2>Rounds</h2>
                                              <table class=" table table-hover table-borderless table-dark">
                                                <tr>
                                                  <th>Round</th>
                                                  <th>Option A</th>
                                                  <th>Option B</th>
                                                  <th scope="col">T.G.J.</th>
                                                  <th scope="col">T.D.J.</th>
                                                  <th scope="col">T.G.A.</th>
                                                  <th>Oynanma Sayısı</th>
                                                  <th>Kullanıcı Sayısı</th>
                                                </tr>
                                                <tr v-for="round in gameDetailData.rounds">
                                                  <td>{{round.roundNumber}}</td>
                                                  <td>{{round.optionA}}</td>
                                                  <td>{{round.optionB}}</td>
                                                  <td>{{round.graphUsed}}</td>
                                                  <td>{{round.changeUsed}}</td>
                                                  <td>{{round.passUsed}}</td>
                                                  <td>{{round.playedCount}}</td>
                                                  <td>{{round.userCount}}</td>
                                                </tr>
                                              </table>
                                          </div>
                                          <div class="user-detail" v-if="statisticOpen == false">
                                               <div class="d-flex align-items-center w-100 justify-content-end">                                   
                                                      <button class="modal-default-button" style="position: absolute;top: -50px;" @click="turnLeaderboard()">
                                                          <i class="icon-action-undo"></i>
                                                      </button>
                                                  </div>
                                                     <div class="d-flex m-body" >
                                                          <div class="col-md-6">
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Adı</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.name"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Telefon</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.phone"/>
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Avatar</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.avatar"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" v-if="actionType == 'update'">
                                                              <h2 class="mr-2">Device ID</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.device_id"/>
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2 w-125">Konuk Oyuncu</h2>
                                                            
                                                              <div class="d-flex">
                                                                <label class="radio">
                                                                  <input type="radio" name="guest" id="one" v-bind:value="true" v-model="modalDetailData.guest">
                                                                  <span>Evet</span>
                                                              </label>
                                                              <label class="radio">
                                                                  <input type="radio" name="guest" id="two" v-bind:value="false" v-model="modalDetailData.guest">
                                                                  <span>Hayır</span>
                                                              </label>
                                                              </div>
                                                              
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2 w-125">Mesaj İzni</h2>
                                                              <div class="d-flex">
                                                                <label class="radio">
                                                                  <input type="radio" name="messages" id="one" v-bind:value="true" v-model="modalDetailData.receivesMessages">
                                                                  <span>Var</span>
                                                              </label>
                                                              <label class="radio">
                                                                  <input type="radio" name="messages" id="two" v-bind:value="false" v-model="modalDetailData.receivesMessages">
                                                                  <span>Yok</span>
                                                              </label>
                                                              </div>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Ana Oyun Kazanma</h2>
                                                              <input type="text" class="form-control" v-model.number="modalDetailData.wonMain"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Yan Oyun Kazanma</h2>
                                                              <input type="text" class="form-control" v-model.number="modalDetailData.wonSub"/>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-6">
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Soyadı</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.lastname"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Email</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.email"/>
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Skor</h2>
                                                              <input type="text" class="form-control" v-model.number="modalDetailData.score"/>
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Total</h2>
                                                              <input type="text" class="form-control" v-model.number="modalDetailData.total"/>
                                                            </div>
                                                            <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Balance</h2>
                                                              <input type="text" class="form-control" v-model.number="modalDetailData.balance"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">GPS ADID</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.gps_adid"/>
                                                            </div>
                                                              <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">IDFA</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.idfa"/>
                                                            </div>
                                                                <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">Skor</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.score"/>
                                                            </div>
                                                                  <div class="d-flex align-items-center h-60" >
                                                              <h2 class="mr-2">IBAN</h2>
                                                              <input type="text" class="form-control" v-model="modalDetailData.iban"/>
                                                            </div>
                                                          </div>
                                                      
                                                          
                                                        
                                                        </div>
                                          </div>
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
          
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;justify-content:center;">
                            <button type="button" class="btn btn-warning open-mdl" v-bind:id="props.row.id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Görüntüle</button>
                            <!-- <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i> Oyunu Sil</button> -->
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2';

export default {
  name: "Subgames",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Başlangıç Tarihi',
          field: 'start',
        },
        {
          label: 'Bitiş Tarihi',
          field: 'end',
        },
         {
          label: 'Oyun ID',
          field: 'id',
        },
        {
          label: 'İşlem',
          field: 'before',
          width: '200px',
        }
      ],
       gameDetailData: 
                          {
                name: '',
                type: 'subgame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                },
                adDuration: 0,
                adOfferPercentage: 0.5,
                maxPlayerCount: 0,
                intermissionDuration: 30,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                noJokersAllowedPlayerCount:0,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    cash: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                },
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: {1:1, 2:1},
},
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      modalDetailData:{
        balance: 0,
        score: 0,
        wonMain: 0,
        wonSub: 0,
        total: 0,
        receivesMessages: true,
        avatar: '',
        device_id: '',
        guest: true,
        gps_adid: null,
        idfa: null,
        email: '',
        lastname: '',
        name: '',
        phone: '',
        iban: '',
      },
      statisticOpen: true,
      targetUserId: '',
    };
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/statistics', {params:{
            offset:0,
            limit:1000,
            }})
              .then((response) => {
                const data = response.data.map(item => {
                  const start = new Date(item.startDate);
                  start.setUTCHours(start.getHours());
                  const end = new Date(item.endDate);
                  end.setUTCHours(end.getHours());
                  return {start:start.toISOString().replace(/T/, ' ').replace(/\..+/, ''),end:end.toISOString().replace(/T/, ' ').replace(/\..+/, ''),id:item._id,leaderboard:item.leaderboard};
                })
                this.rows = data;
                console.log(response.data);
                console.log(data);
            });
          },
          getUserDetail(event){
            this.statisticOpen = false;
            this.targetUserId = event.currentTarget.id;
            console.log(this.targetUserId); 
               axios.get( this.$hostname +'/users', {params:{
                  id: this.targetUserId,
            
            }})
              .then((response) => {
                const userArray = [response.data];
                    console.log(response.data);
                    this.modalDetailData = response.data;
                    console.log(this.gameDetailData);
            });
          },
          _getGameDetail(event){
            this.actionType = "update";
            this.showModal = true;
            this.gameDetailData = [];
            this.targetId = event.currentTarget.id;
          console.log(this.targetId); 

             axios.get( this.$hostname +'/statistics', {params:{
            id: this.targetId,
            }})
              .then((response) => {
                this.gameDetailData = response.data;
                console.log(this.gameDetailData);
            });
          },
           _deleteGame(event){
            this.removeId = event.currentTarget.id;
                Swal.fire({
                title: 'Silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                    console.log("remove id", this.removeId);
                  axios.delete(this.$hostname +'/statistics', {params:{
                  id: this.removeId,
                }})
                .then(
                  this.showModal = false
                )
                  Swal.fire(
                    'Silindi!',
                    'Oyun başarıyla silindi !',
                    'success'
                  )
                }
              })
              
           
          },
          closeModal(){
            this.showModal = false;
            this.statisticOpen = true;
          },
          turnLeaderboard(){
             this.statisticOpen = true;
          },
          _addNewPrize(){
              this.gameDetailData.prizes.qualifying.push({rank: this.newRank, prize: {coin: this.newCoin, pass: this.newPass}});
            //  this.gameDetailData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
          },
          _removeElement(index) {
              this.gameDetailData.prizes.qualifying.splice(index, 1);
            },
          _updateGame(event){
              this.updateId = event.currentTarget.id;
              console.log("update id", this.updateId);
              console.log(this.gameDetailData);
                    Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                  axios.put(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
                .then(
                  this.showModal = false
                )
                  Swal.fire(
                    'Güncellendi!',
                    'Oyun başarıyla Güncellendi !',
                    'success'
                  )
                }
              })
           
          },
          _newGameDataModal(){
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.gameDetailData);
          },
          _createGame(){
            axios.post(this.$hostname +'/games', this.gameDetailData, {headers: { 'Content-Type': 'application/json'}})
              .catch(error => {
                  if (!error.response) {
                      // network error
                      this.errorStatus = 'Error: Network Error';
                  } else {
                      this.errorStatus = error.response.data.message;
                  }
                });
          }
        

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>

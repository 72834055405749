
//http://3.120.173.33/games
//http://3.120.173.33/users
//socket io 2.li version kullanılcak
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';




import { Pie, mixins } from "vue-chartjs";
export default {
  extends: Pie,
  props: ["data", "options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, {
      borderWidth: "10px",
      hoverBackgroundColor: "red",
      hoverBorderWidth: "10px"
    });
  },
  watch: {


  },
};
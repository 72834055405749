<template>
<div class="app-content content" v-if="liveData.status == undefined">
   <div class="p-5">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-12">
            <div>
                <h1 class="font-medium-1 text-left">Canlı bir oyun bulunmamaktadır. Yeni oyunun başlamasına son</h1>
            </div>
            <div class="align-self-center d-flex align-items-center">
            
                <i class="icon-bell font-large-2 float-left" style="color:#dcdcdc;"></i>
            <h2 class="font-xlarge" style="font-size:40px;">{{convertedTime}}</h2>
            </div>
        </div>
      </div>
   </div>
</div>
  <div class="app-content content drk" v-else>
   <div class="p-5">
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-12">
            <div class="card bg-gradient-directional-info">
                <div class="card-content">
                    <div class="card-body">
                        <div class="media d-flex">
                            <div class="align-self-center">
                                <i class="icon-bell text-white font-large-2 float-left"></i>
                            </div>
                            <div class="media-body text-white text-right">
                                <h2 class="text-white" > {{liveData.status}}</h2>
                                <span>Durum</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-12">
            <div class="card bg-gradient-directional-warning">
                <div class="card-content">
                    <div class="card-body">
                        <div class="media d-flex">
                            <div class="align-self-center">
                                <i class="icon-support text-white font-large-2 float-left"></i>
                            </div>
                            <div class="media-body text-white text-right">
                                <h2 class="text-white">{{liveData.round.number}}</h2>
                                <span>Tur</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-12">
            <div class="card bg-gradient-directional-success">
                <div class="card-content">
                    <div class="card-body">
                        <div class="media d-flex">
                            <div class="align-self-center">
                                <i class="icon-clock text-white font-large-2 float-left"></i>
                            </div>
                            <div class="media-body text-white text-right">
                                <h2 class="text-white">{{liveData.timer}}</h2>
                                <span>Kalan Süre</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
   </div>
   <div class="px-5">
     <h1 class="text-left">Bu Turda</h1>
     <div class="row pt-3">
      <div class="col-md-3  " style="max-height:400px;">
           <pie-chart ref='chart' :data="chartData" :options="chartOptions"></pie-chart>
           <div class="d-flex">
            <div class="col-md-6 text-center"><h2 v-if="liveData.round !== undefined">A: {{liveData.round.optionA}}</h2></div>
            <div class="col-md-6 text-center"><h2 v-if="liveData.round !== undefined">B: {{liveData.round.optionB}}</h2></div>
           </div>
      </div>
       <div class="col-md-9 d-flex align-items-center">
          <div class="row">
              <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-graph info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3 v-if="liveData.round && liveData.round.graph > 0">{{liveData.round.graph}}</h3>
                                      <h3 v-else >0</h3>
                                      <span>Grafik Jokeri</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-refresh info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3 v-if="liveData.round && liveData.round.change > 0">{{liveData.round.change}}</h3>
                                      <h3 v-else >0</h3>
                                      <span>Değişim Jokeri</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-key info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.round.pass}}</h3>
                                      <span>Geçiş Anahtarı</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-like info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.round.usersPlayed}}</h3>
                                      <span>Seçim Yapmış</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-control-play info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.round.continuingUserCount}}</h3>
                                      <span>Devam Eden Oyuncu</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
       </div>
     </div>
      <h2 class="text-left mt-3">Bu Oyunda </h2>
       <div class="row pt-3">
       <div class="col-md-12">
          <div class="row">
                <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-graph info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3 v-if="liveData.graph !== undefined">{{liveData.graph}}</h3>
                                      <h3 v-else>0</h3>
                                      <span>Grafik Jokeri</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-refresh info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.change}}</h3>
                                      <span>Değişim Jokeri</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-key info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.pass}}</h3>
                                      <span>Geçiş Anahtarı</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-eye info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.watchingUserCount}}</h3>
                                      <span>İzleyici</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                  
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="card">
                      <div class="card-content">
                          <div class="card-body">
                              <div class="media d-flex">
                                  <div class="align-self-center">
                                      <i class="icon-control-play info font-large-2 float-left"></i>
                                  </div>
                                  <div class="media-body text-right">
                                      <h3>{{liveData.startingUserCount}}</h3>
                                      <span>Başlangıç Oyuncu</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
       </div>
     </div>
    <template class="px-" v-if="liveData.status == 'end'">
        <table class="table table-hover table-borderless table-dark">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Kullanıcı Adı</th>
            <th scope="col">Harcanan</th>
            <th scope="col">T.G.J.</th>
            <th scope="col">T.D.J.</th>
            <th scope="col">T.G.A.</th>
            <th scope="col">Kazanan</th>
            <th scope="col">Geçiş Anahtarı</th>
            <th scope="col">Coin</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(lead,index) in leaderBoard">
            <th scope="row">{{index}}</th>
            <td>{{lead.username}}</td>
            <td>{{lead.coinsSpent}}</td>
            <td>{{lead.graph}}</td>
            <td>{{lead.change}}</td>
            <td>{{lead.pass}}</td>
            <td class="d-flex align-items-center"> 
                <template v-if="lead.isWinner == true">
                Kazandı  <i class="fa fa-info-circle" @click="_getUserDetail()" style="font-size: 20px;margin-left: 60px;position: absolute;cursor:pointer;"></i>
                    </template>
                    <template v-else>
            x
                    </template> </td>
            <td>{{lead.pass}}</td>
            <td>{{lead.coins}}</td>
            </tr>
        
        </tbody>
        </table>
        <modal v-if="showModal" @close="showModal = false">
                <template v-slot:modal-header>
                    <h1 >Oyuncu Bilgileri</h1>
                    <input type="hidden" >
                </template>
                <template v-slot:modal-body>
                   <div class="d-flex align-items-center mt-2 acc-inside">
                        <h2 class="mr-2 col-md-6 text-left">Adı Soyadı</h2>
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="d-flex align-items-center mt-2 acc-inside">
                        <h2 class="mr-2 col-md-6 text-left">IBAN</h2>
                        <input type="text" class="form-control"/>
                    </div>
                    <div class="d-flex align-items-center mt-2 acc-inside">
                        <h2 class="mr-2 col-md-6 text-left">Telefon Numarası</h2>
                        <input type="text" class="form-control"/>
                    </div>
                </template>                         
        </modal>
    </template>


   </div>
  </div>
</template>




<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import io from "socket.io-client";
import PieChart from "../js/PieChart.js";






export default {
  name: "LiveGame",
  components: {
      Modal,
      PieChart
  },
   data(){
    return {
        liveData: [],
        leaderBoard: [],
        showModal:false,
        chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: ["A", "B"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#1d1062", "#3b1798"],
            data: [500, 500]
          }
        ]
      },
      convertedTime: {},
     
    }
   },
   methods: {
        _convertHMS(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours   = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
       },
       _ioConnect(){
           console.log("kk");
              const socket = io('https://adminapi.renktekal.com/');
                socket.on("data", data => {
                   this.liveData = data;
                   this.leaderBoard = this.liveData.leaderboard;
                        if(this.liveData.round > 0){
                            this.chartData.datasets[0].data[0] = this.liveData.round.optionA;
                            this.chartData.datasets[0].data[1] = this.liveData.round.optionB;
                        }else{
                            this.convertedTime = this._convertHMS(this.liveData.timer);
                            console.log(this.convertedTime)
                        }
                        console.log("round data", this.chartData.datasets[0].data);
                        console.log(this.liveData);
            
                });
       },
       _getUser(){
           axios.get( this.$hostname +'/users', {params:{
               id: "604ec53d4d4ba70011e7a2b1",
            }})
              .then((response) => {
                console.log("id", response.data);
            });
       },
       _getUserDetail(){
           this.showModal = true;
       },
      
     
   },
   mounted(){
       this._ioConnect();
       this._getUser();

   }

}


</script>
<template>
  
</template>

<script>
export default {
 name: "User Detail",

  data() {
    return {

    } 
  }


}
</script>

<style>

</style>

<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">IAP Ayarları</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Ayar Ekle</button></li>
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>

           <!-- use the modal component, pass in the prop -->
   
                                  <modal v-if="showModal" @close="showModal = false">
                                      <template v-slot:modal-header>
                                        <h1 >{{modalDetailData.key}}</h1>
                                       
                                        <input type="hidden" v-model="actionType">
                                      </template>
                                       <template v-slot:modal-body>
                                        <div class="d-flex m-body" >
                                          <div class="col-md-6">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Coin</h2>
                                              <input type="text" class="form-control" v-model.number="modalDetailData.coin"/>
                                            </div>
                                         
                                          </div>
                                           <div class="col-md-6">
                                             <div class="d-flex align-items-center h-60" >
                                              <h2 class="mr-2">Key</h2>
                                              <input type="text" class="form-control" v-model="modalDetailData.key"/>
                                            </div>
                                               </div>
                                          </div>
                                                <div class="d-flex align-items-center mt-2 m-body">
                                                              <h2 class="mr-2 col-md-6 text-left">Ayar Resmi (PNG Harici Yüklenemez.)</h2>
                                                               <input type="hidden" class="form-control" v-model="modalDetailData.imageUrl"/>
                                                              <!-- <input type="file" @change="_uploadImage" name="image" id="image"  accept="image/*" > -->
                                                                    <input
                                                                          accept="image/x-png"
                                                                          type="file"
                                                                          name="file-input"
                                                                          id="file-input"
                                                                          class="file-input__input"
                                                                          @change="_uploadImage"
                                                                        />
                                                              <div>
                                                                        <label class="file-input__label" for="file-input">
                                                                          <svg
                                                                            aria-hidden="true"
                                                                            focusable="false"
                                                                            data-prefix="fas"
                                                                            data-icon="upload"
                                                                            class="svg-inline--fa fa-upload fa-w-16"
                                                                            role="img"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512"
                                                                          >
                                                                          
                                                                            <path
                                                                              fill="currentColor"
                                                                              d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                                                            ></path>
                                                                          </svg>
                                                                          <span>Resim Seç</span></label
                                                                        >
                                                                        <div class="load mt-3" v-bind:class="imageLoadStatus ? 'Replace' : 'loaded'">
                                                                          <svg class="loader" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                                                                            <path fill="#4468d6" d="M256.011 0c-12.852 0-23.273 10.42-23.273 23.273v93.091c0 12.854 10.421 23.274 23.273 23.274 12.853 0 23.272-10.421 23.272-23.274v-93.091c0-12.853-10.419-23.273-23.272-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.4" d="M256.011 372.363c-12.852 0-23.273 10.421-23.273 23.272v93.091c0 12.853 10.421 23.274 23.273 23.274 12.853 0 23.272-10.421 23.272-23.274v-93.091c0-12.853-10.419-23.272-23.272-23.272z"></path>
                                                                            <path fill="#4468d6" opacity="0.8" d="M173.725 140.809l-65.826-65.828c-9.086-9.089-23.822-9.089-32.912 0-9.089 9.089-9.089 23.824 0 32.912l65.826 65.828c4.544 4.544 10.5 6.816 16.455 6.816s11.912-2.273 16.455-6.816c9.090-9.089 9.090-23.823 0.001-32.912z"></path>
                                                                            <path fill="#4468d6" opacity="0.1" d="M459.806 232.727h-46.546c-12.853 0-23.272 10.421-23.272 23.273 0 12.853 10.419 23.272 23.272 23.272h46.546c12.853 0 23.272-10.419 23.272-23.273 0-12.852-10.421-23.273-23.272-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.3" d="M365.557 338.281c-9.087-9.089-23.823-9.087-32.913 0-9.088 9.089-9.087 23.823 0 32.913l65.828 65.825c4.544 4.544 10.502 6.817 16.457 6.817 5.957 0 11.913-2.274 16.455-6.817 9.089-9.089 9.089-23.825 0-32.913l-65.828-65.825z"></path>
                                                                            <path fill="#4468d6" opacity="0.6" d="M139.637 256c0-12.852-10.421-23.273-23.273-23.273h-93.091c-12.853 0-23.273 10.421-23.273 23.273 0 12.853 10.42 23.272 23.273 23.272h93.091c12.852 0 23.273-10.419 23.273-23.273z"></path>
                                                                            <path fill="#4468d6" opacity="0.5" d="M173.735 338.283c-9.087-9.089-23.825-9.089-32.912 0l-65.825 65.825c-9.089 9.087-9.089 23.825 0 32.913 4.544 4.544 10.501 6.815 16.457 6.815s11.913-2.271 16.455-6.815l65.825-65.825c9.089-9.087 9.089-23.822 0-32.911z"></path>
                                                                          </svg>
                                                                      </div>
                                                                       </div>
                                                                         <img id="output" class="special-image ml-3" v-bind:class="{ loaded: modalDetailData.imageUrl }" :src="imgPath">
                                                                      </div>
                                                   
                                                                      
                                                               
                                           
                                         
                                    
                                        <div class="w-100 text-left mt-3">
                                          <!-- <h1>Jokerler</h1> -->
                                        </div>
                                          <div class="col-md-12 position-relative mt-5">
                                              <vsa-list class="w-100" v-for="item in modalDetailData.jokers">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading>
                                                  Pass Joker
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside">
                                                        
                                                          <!-- <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">İd</h2>
                                                            <input type="text" class="form-control" v-model="item.id"/>
                                                          </div> -->
                                                           <div class="d-flex align-items-center mt-2">
                                                            <h2 class="mr-2 col-md-6 text-left">Miktar</h2>
                                                            <input type="text" class="form-control" v-model.number="item.quantity"/>
                                                          </div>
                                                   
                                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                       
                                    
                                                          </div>
                                                   </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                                          </div>
                                          <div class="w-100 d-flex align-items-center justify-content-end mt-3">
                                                            <button  v-if="actionType == 'update'" type="button" class="btn mr-1 mb-1 btn-success" v-bind:id="listedData._id"  @click="_updateGame($event)"><i class="fa fa-edit mr-1"></i> Ayarı Güncelle</button>
                                                            <button  v-if="actionType == 'add'" type="button" class="btn mr-1 mb-1 btn-success"  @click="_createGame($event)"><i class="fa fa-edit mr-1"></i> Ayar Ekle</button>
                                                            <button type="button" class="btn mr-1 mb-1 btn-danger"><i class="fa fa-trash mr-1"></i> Ayarı Sil</button>
                                                          </div>
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;max-width:300px">
                            <button type="button" class="btn mr-1 btn-warning open-mdl" v-bind:id="props.row.originalIndex" @click="_getGameDetail(props.row);"><i class="fa fa-edit mr-1"></i> Düzenle</button>
                            <button type="button" class="btn  btn-danger delete" v-bind:id="props.row._id" @click="_deleteGame($event)"><i class="fa fa-trash mr-1"></i>Sil</button>
                           
                          </span>
                          <span v-else-if="props.column.field == 'after'">
                            after
                          </span>
                          <span v-else>
                            {{props.formattedRow[props.column.field]}}
                          </span>
                      </template>
                    </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2'

export default {
  name: "Bundles",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Key',
          field: 'key',
        },
         {
          label: 'ID',
          field: '_id',
        },
     
        {
          label: 'İşlem',
          field: 'before',
          width: '200px',
        }
      ],
      listedData:[],
      number: 0,
      modalDetailData:{
          "coin": 0,
          "key": '',
          "imageUrl": '',
          "jokers": [
            {
              "id": "pass",
              "quantity": 0
            }
          ],

        },
        modalNewData:{
          "coin": 0,
          "key": '',
           "imageUrl": '',
          "jokers": [
            {
              "id": "pass",
              "quantity": 0
            }
          ],

        },
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      imagePath: '',
      
      imageLoadStatus: true,
      imgPath:'',
      imageUpload: false,
    }
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/bundles', {params:{
            type: 'maingame',
            }})
              .then((response) => {
                this.rows = response.data;
                console.log(this.rows);
            });
          },
          _getGameDetail(row){
            this.actionType = "update";
            this.showModal = true;
            this.modalDetailData = row;
            console.log('listed data' ,this.modalDetailData);
            // this.number = event.currentTarget.id;
            // console.log(this.number); 
             this.imgPath = 'http://'+this.modalDetailData.imageUrl;
            console.log('row data',row);

          },
          _deleteGame(event){
              this.removeId = event.currentTarget.id;
              console.log("remove id", this.removeId);
              Swal.fire({
                title: 'Silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sil!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                
                    console.log("remove id", this.removeId);
                      axios.delete(this.$hostname +'/bundles', {params:{
                    id: this.removeId,
                  }}).then( () =>
                    this._getListData()
                  );
                  Swal.fire(
                    'Silindi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          _addNewPrize(){
              this.listedData.prizes.qualifying.push({rank: this.newRank, prize: {coin: this.newCoin, pass: this.newPass}});
            //  this.listedData.prizes.qualifying.prize.push({coin: this.newCoin , pass: this.newPass });
          },
          _removeElement(index) {
              this.listedData.prizes.qualifying.splice(index, 1);
            },
          _updateGame(event){
  
              Swal.fire({
                title: 'Güncellemek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Güncelle!',
                cancelButtonText: 'İptal'
              }).then((result) => {
                if (result.isConfirmed) {
                  //this.updateId = event.currentTarget.id;
                  console.log("update id", this.updateId);
                  console.log(this.modalDetailData);
                  const {vgt_id,originalIndex,...args} = this.modalDetailData;
                  console.log(args);
                  axios.put(this.$hostname +'/bundles', args, {headers: { 'Content-Type': 'application/json'}})
                .then(
                  this.showModal = false
                
                )
                  Swal.fire(
                    'Güncellendi!',
                    'Güncelleme başarıyla gerçekleştirildi !',
                    'success'
                  )
                }
              })
          },
          _newGameDataModal(){
           this.modalDetailData = this.modalNewData;
            this.showModal = true;
            this.actionType = "add";
            console.log("show modal");
            console.log("show modal", this.modalDetailData);
          },
          _createGame(){
            axios.post(this.$hostname +'/bundles', this.modalDetailData, {headers: { 'Content-Type': 'application/json'}})
              .then(() => {
                 this.showModal = false;
                 this._getListData();
              })
          },
               _uploadImage (e) {
            let img = e.target.files[0]
            let fd= new FormData()
            this.imageLoadStatus = false;
            console.log("img", img);
             console.log("path data", this.imageLoadStatus);
             $('.special-image').attr('src', img);
             document.getElementById('output').src = window.URL.createObjectURL(img);
             $(".load").addClass("loaded");
             $(".special-image").addClass("loaded");
            fd.append('file', img)
             if (img.size > 480 * 560) {
                  e.preventDefault();
                  alert('480x560 ölçülerinde png harici kabul edilmez.)');
                  return;
              }else{
                      axios.post(this.$hostname +'/upload', fd,)
                        .then(resp => {
                         this.modalDetailData.imageUrl = resp.data;
                         this.imgPath = 'http://'+this.modalDetailData.imageUrl;
                          console.log("path", this.modalDetailData.imageUrl);
                          this.imageLoadStatus = true;
                          console.log("path data", this.imageLoadStatus);
                          $(".load").removeClass("loaded");
                 
                        })
              }
          }

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>

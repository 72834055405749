
<template>
  <div class="app-content content home">
    <section id="base-style">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1 class="mb-0 text-left">Raporlanan Kullanıcılar</h1>
             
              <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <!-- <li> <button type="button" class="btn mr-1 btn-warning open-mdl"  @click="_newGameDataModal()"><i class="fa fa-edit mr-1"></i> Yeni Oyun Ekle</button></li> -->
                  <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                  <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                  <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                  <li><a data-action="close"><i class="ft-x"></i></a></li>
                </ul>
              </div>
            </div>
           <!-- use the modal component, pass in the prop -->
   
                                  <modal v-if="showModal" @close="showModal = false">
                                      <template v-slot:modal-header>
                                  
                                        <input type="hidden" v-model="actionType">
                                      </template>
                                       <template v-slot:modal-body>
                                      <vsa-list class="w-100" v-for="(item,index) in gameDetailData" :key="index">
                                   <!-- Here you can use v-for to loop through items  -->
                                                <vsa-item>
                                                  <vsa-heading class="d-flex justify-content-between">
                                                    <div class="d-flex w-100 justify-content-between align-items-center" >
                                                    <p class="mb-0 mr-2" v-if="item.checked === true" style="font-size: 25px;
    background: green;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;"><i class="icon-check"></i></p>
                                                       {{item.userId}}
                                                    </div>
                                                  </vsa-heading>

                                                  <vsa-content>
                                                   <div class=" w-100 acc-inside d-flex">
                                                      <div class="col-md-4">
                                                        <h2>Tarih: <b>{{item.date}}</b></h2>
                                                      </div>
                                                      <div class="col-md-4">
                                                          <h2>Raporlayan: <b>{{item.reporterId}}</b></h2>
                                                      </div>
                                                      <div class="col-md-4">
                                                          <h2>Mesaj Id: <b>{{item.messageId}}</b></h2>
                                                      </div>
                                                    
                                                 
                                                   </div>
                                                       <div class="w-100 d-flex justify-content-between w-100 align-items-center">
                                                         <div class="chatbox mt-3">
                                                           {{item.text}}
                                                
                                                         </div>
                                                               <div class="d-flex justify-content-between mt-3 align-items-center">
                                                  
                                                   <button type="button" class="btn ml-1 btn-warning " v-bind:id="item._id" @click="_seenUser($event)"><i class="icon-check mr-1"></i> Okundu Olarak İşaretle</button> 
                                                    </div>
                                                       </div>
                                                  </vsa-content>
                                                </vsa-item>
                                              </vsa-list>
                              
                                          
                                      </template>
                                  </modal>
                                  <!-- MODAL END -->
				<div class="card-content collapse show">
					<div class="card-body card-dashboard">{{this.rows._id}}


                   <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      :search-options="{
                        enabled: true
                      }"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50],
                        dropdownAllowAll: false,
                        setCurrentPage: 2,
                        nextLabel: 'next',
                        prevLabel: 'prev',
          
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                        infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
                      }">
                      <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'before'" style="display:flex;justify-content:center;">
                            <button type="button" class="btn btn-warning open-mdl" v-bind:id="props.row._id" @click="_getGameDetail($event);"><i class="fa fa-edit mr-1"></i> Görüntüle</button>
                            <button type="button"  class="btn ml-1 btn-danger delete" v-bind:id="props.row._id" @click="_banUser($event)"><i class="icon-user-unfollow mr-1"></i> Banla</button> 
                            <button type="button" class="btn ml-1 btn-info delete" v-bind:id="props.row._id" @click="_warnUser($event)"><i class="icon-user-unfollow mr-1"></i> Uyar</button> 
                          </span>
                                <span v-if="props.column.field == 'bann' " style="display:flex;justify-content:center;">
                                <div v-if="props.row.ban">
                              <p class="danger mb-0" v-if="props.row.ban.type==='warning'"><i class="icon-user-unfollow mr-1"></i>Uyarıldı</p>
                              <p class="warning mb-0" v-if="props.row.ban.type==='ban'"><i class="icon-user-unfollow mr-1"></i>Banlandı</p>
                 
                              </div>
                          </span>
                         <span v-if="props.column.field == 'phone'" style="display:flex;justify-content:center;">
                                {{props.row.phone}}
                          </span>
                              <span v-if="props.column.field == '_id'" style="display:flex;justify-content:center;">
                                {{props.row._id}}
                          </span>
                      </template>
                
                    </vue-good-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
   
  </div>

</template>


<script>
import Modal from '../components/Modal.vue'; 
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;
import Swal from 'sweetalert2';

export default {
  name: "Subgames",
  components: {
    Modal,
  },
  data(){
    return {
      columns: [
        {
          label: 'Telefon',
          field: 'phone',
        },
         {
          label: 'User ID',
          field: '_id',
        },
        {
          label: 'Durum',
          field: 'bann',
          width: '100px',
        },
        {
          label: 'İşlem',
          field: 'before',
          width: '200px',
        }
        
      ],
       gameDetailData: 
                          {
                name: '',
                type: 'subgame',
                interval: '',
                time: {
                  hour: 0,
                  min: 0,
                  month: undefined,
                  day : undefined,
                  dayInWeek: undefined,
                },
                adDuration: 0,
                adOfferPercentage: 0.5,
                maxPlayerCount: 0,
                intermissionDuration: 30,
                lobbyDuration: 300,
                noJokersAllowedPlayerCount: 10,
                noJokersAllowedPlayerCount:0,
                passAllowedDuration: 0,
                prices: {
                  change: {
                    base: 0,
                    increment: 0
                  },
                  graph: {
                    base: 0,
                    increment: 0
                  },
                  pass: {
                    base: 0,
                    increment: 0
                  }
                },
                prizes: {
                  winner: {
                    cash: 0
                  },
                  runnerups: {
                    coin: 0,
                    pass: 0
                  },
                  qualifying: [],
                },
                roundDuration: 30,
                roundReward: 25,
                roundRewardIncrement: 10,
                roundRewardPeriodMultiplier: {1:1, 2:1},
},
      showModal:false,
      rows: [],
      targetId: '',
      newData: [],
      removeId: '',
      updateId: '',
      newRank: '0',
      newCoin: '0',
      newPass: '0',
      actionType: '',
      userData: {},
      
    };
  },

  methods: {
          _getListData(){
            axios.get( this.$hostname +'/users/reported', {params:{
            offset:0,
            limit:10,
            }})
              .then((response) => {
                this.rows = response.data;
                console.log(this.rows);
            });
          },
          _getGameDetail(event){
         
            this.actionType = "update";
            this.showModal = true;
            this.gameDetailData = [];
            this.targetId = event.currentTarget.id;
          console.log(this.targetId); 

             axios.get( this.$hostname +'/reports', {params:{
            userId: this.targetId,
            offset:0,
            limit:10,
            }})
              .then((response) => {
                this.gameDetailData = response.data;
                console.log("resp", response.data);
            });
          },
    

          _banUser(event){
                this.targetId = event.currentTarget.id;
               axios.post(this.$hostname +'/users/ban', {
                 _id : this.targetId,
                 type : 'ban'
               }, {headers: { 'Content-Type': 'application/json'}})
              .catch(error => {
                  if (!error.response) {
                      // network error
                      this.errorStatus = 'Error: Network Error';
                  } else {
                      this.errorStatus = error.response.data.message;
                  }
                }) .then((response) => {
                console.log("resp", response.data);
            });
          },
              _warnUser(event){
                this.targetId = event.currentTarget.id;
               axios.post(this.$hostname +'/users/ban', {
                 _id : this.targetId,
                 type : 'warning'
               }, {headers: { 'Content-Type': 'application/json'}})
              .catch(error => {
                  if (!error.response) {
                      // network error
                      this.errorStatus = 'Error: Network Error';
                  } else {
                      this.errorStatus = error.response.data.message;
                  }
                }) .then((response) => {
                console.log("resp", response.data);
            });
          },
             _seenUser(event){
                this.targetId = event.currentTarget.id;
               axios.put(this.$hostname +'/reports', {
                 _id : this.targetId,
                 checked : true
               }, {headers: { 'Content-Type': 'application/json'}})
              .catch(error => {
                  if (!error.response) {
                      // network error
                      this.errorStatus = 'Error: Network Error';
                  } else {
                      this.errorStatus = error.response.data.message;
                  }
                }) .then((response) => {
                console.log("resp", response.data);
            });
          }
        

  },
  mounted(){
    this._getListData();
  },
  computed:{
    sortedData (){

    },
  }

};
</script>
